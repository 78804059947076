<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-cog"></i> Nightly Settings</h2>

        <div class="container col-12"  style="background-color: white; padding: 3%">
            <div class="row">
                <div class="col-12">
                    <p>Crawler nightly system settings configuration</p>
                </div>
            </div>

<!--        -->
<!--        <div class="col-12" style="background-color: white; padding: 3%">-->
<!--            <div class="row">-->
<!--                <p>Crawler nightly system settings configuration</p>-->
<!--            </div>-->



            <div class="card p-5">

                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label>Need Limit</label>
                            <input
                                type="number"
                                v-model="item.needLimit"
                                step="1.0"
                                class="form-control"
                            />
                            <div class="form-text text-muted">
                                Needs minimum value
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label>Need Max</label>
                            <input
                                type="number"
                                v-model="item.needMax"
                                step="1.0"
                                class="form-control"
                            />
                            <div class="form-text text-muted">
                                Needs maximum value
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label>Queue Check Ip</label>
                            <select v-model="item.queueCheckIp" class="form-control">
                                <option value="yes">Yes</option>
                                <option value="nationwide">Nationwide</option>
                                <option value="no">No</option>
                            </select>
                            <div class="form-text text-muted">Target location IP check.</div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="form-group">
                            <label>Queue Pitch</label>
                            <select v-model="item.queuePitch" class="form-control">
                                <option value="blitz">Blitz</option>
                                <option value="quality">Quality</option>
                                <option value="speed">Speed</option>
                            </select>
                            <div class="form-text text-muted">Queue activity mode.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-center pt-3" >
                <div class="col-12 col-md-12 d-flex justify-content-center">
                    <b-button  class="" variant="success" @click="save()"><strong>Save Configuration</strong></b-button>
                </div>
            </div>
        </div>

    </div>

</template>
<script>
//import nightlyConf from "@/components/nightlyConfig";
import axios from "axios";
import swal from "sweetalert";

export default {
    name: "Settings",
    props: ["user"],
   // components: {nightlyConf},
    data: function () {
        return {
            item :{}
        };
    },
    created: function () {
        this.$root.preloader = false;
        this.getSettings()

    },
    methods: {
        getSettings(){
            //Load the settings
            var request = { apikey: this.$root.apikey };


            axios
                .get(`${this.$root.serverUrl}/admin/autoItems`, { params: request })
                .then(

                    function (resp) {
                        console.log(resp.data.data)
                        this.$root.preloader = false;
                        this.item =
                            resp && resp.data && resp.data.data
                                ? Object.assign(this.item, resp.data.data[0])
                                : this.item;
                        this.$forceUpdate();
                    }.bind(this)
                );
            this.getProviders();
        },
        save() {


            var request = { apikey: this.$root.apikey, args: this.item };


            axios({
                url: `${this.$root.serverUrl}/admin/autoItems`,
                data: request,
                responseType: "json",
                method: this.item["_id"] ? "PUT" : "POST",
            }).then((resp) => {
                if(resp.data.error){
                    swal({ title: 'Warning', text: resp.data.error, icon: 'error' }).then(() => {

                    })
                }else{
                    swal({ title: 'Success', text: 'Reset Done.', icon: 'success' }).then(() => {

                    })
                }

            }).catch(() => {
                swal("Oops!", "There was an issue Resting", "error");
            })
        },
    },
}
</script>
<style>

</style>
